.messages-component{
  &-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
  }
  &-holder{
    display: block;
    height: 100%;
    width: 100%;
    max-width: 800px;
    overflow: scroll;
  }
  &-message{
    &-block{
      width: 100%;
      display: flex;
      margin-bottom: 10px;

      &.incoming{
        justify-content: flex-start;
      }
      &.outcoming{
        justify-content: flex-end;
      }
    }
    &-box{
      display: flex;
      flex-direction: column;
      width: fit-content;
      width: -moz-fit-content;
      padding: 10px;
      border-radius: 15px;
      max-width: 375px;

      &.incoming{
        justify-content: flex-start;
        background-color: #e6f7ff;
        border-bottom-left-radius: 0;
        padding-left: 20px;
        padding-right: 30px;
      }
      
      &.outcoming{
        background-color: #1890ff;
        justify-content: flex-end;
        border-bottom-right-radius: 0;
        padding-right: 20px;
        padding-left: 30px;
      }
    }

    &-author{
      margin-bottom: 3px;
      font-size: 12px;
      text-align: left;
      
      &.incoming{
        color: #8c8c8c;
      }

      &.outcoming{
        color: #d9d9d9
      }
    }

    &-content{
      font-size: 14px;

      &.incoming{
        text-align: left;
        color: #141414;
      }

      &.outcoming{
        text-align: right;
        color: #fff;
      }
    }
  }
}