.send-message-action-box-component{
  &-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    max-width: 800px;
  }
  &-input{
    width: 100%;
    height: 100%;
    margin-right: 10px;
    min-height: 100px;
  }
  &-button{
    height: 100%;

  }
}