.header-component{
  &-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    @media screen and (max-width: 1024px){
      margin-bottom: 0;
    }
    &-holder{
      display: flex;
      width: 100%;
      max-width: 800px;
      padding: 35px 25px;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-left{
    display: flex;
    align-items: center;

    & > button {
      margin-left: 15px;
    }
  }
}