.chat-page{
  &-container{
    height: 100%;
    display: flex;
    padding: 0 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: calc(100vh - 152px);

    @media screen and (max-width: 1024px) {
      max-height: calc(100vh - 102px);
    }
  }
}