@import 'https://fonts.googleapis.com/css2?family=Caveat:wght@700&amp;family=Plus+Jakarta+Sans:wght@500;600;700;800&amp;display=swap';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  border: 0;
}
*,
input,
button,
body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

html {
  height: 100%;
  width: 100%;
  & body {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;

    &.foreground {
      background-color: var(--foreground-color);
    }
  }

  & #root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.enable-height{
      height: auto;
    }
  }
}
